import React from 'react';

const AboutStep = ({headerContent, items }) => {
  return (
    <div className='costMargabt'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12 col-12'>
            <div className='aboutStep'>
            {headerContent}
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-12'>
            {items.map((item, index) => (
              <div className="d-flex stepBg" key={index}>
                {item.imgUrl && <img src={item.imgUrl} alt={item.title} className='img-fluid' width={100}   />}
                <div className="card-body m-3">
                  <p className="display-4 fs-5 fw-medium textPara">{item.title}</p>
                  {item.desc && <p>{item.desc}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStep;
