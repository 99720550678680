import React from 'react'
import ImportantDiscloser from './ImportantDiscloser'
import RequestLoan from './RequestLoan'
import FooterLinks from './FooterLinks'

const FooterSection = () => {
  return (
    <div className='footerBg'>
      <ImportantDiscloser/>
      <RequestLoan/> 
      <FooterLinks/>
      
    </div>

  )
}

export default FooterSection
