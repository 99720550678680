import React from 'react'

const TermsofService = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <h4>Terms of Use</h4>
    <div>
        <p>Last Updated: July 1, 2020</p>
        <p> UpMyLoan.com, owns and operates the website located at UpMyLoan.com (the “Site”). UpMyLoan.com provides and maintains this Site for your information, conditioned on your acceptance, without modification, of the terms, conditions and notices contained in these Terms of Use and in our Privacy Policy. By accessing and using the Site, you accept and agree without limitation to the following: </p>
    </div>
    <div>
        <h4>Products and Services</h4>
        <p> <span>UpMyLoan.com</span> is not a lender or lending partner and is not responsible for any lending product advertised or offered to you, or for any credit decisions made about you. No specific loan products are offered to you on this Site by UpMyLoan.com. Specific loan products are offered to you directly by the lender or lending partner. </p>
    </div>
    <div>
        <h4>Esign Consent</h4>
        <p> You agree that clicking “Get Cash Now” (or other such button you use to submit your form) acts as your electronic signature. You further consent that we and any lender or lending partner we share your request with can communicate with you electronically and that any required disclosures may be provided to you electronically, including without limitation any notices of adverse action that a lender or lending partner is required send to you. You also confirm that you have the ability to access documents electronically, including the ability to view and save PDF files. </p>
    </div>
    <div>
        <h4>SMS Terms and Conditions</h4>
        <p> By clicking “Submit”, I agree to receive informational, promotional or marketing related SMS messages from UpMyLoan.com and marketing partners. This is a standard rate subscription service available on most carriers including U.S. Cellular, AT&amp;T, Cellular One, T-Mobile, Sprint, Boost, MetroPCS, Verizon Wireless, Alltel Wireless and Virgin Mobile. Msg&amp;Data Rates May Apply. Requires text-enabled handset. Carriers are not liable for delayed or undelivered messages. You may cancel your subscription by texting STOP in response to the message you receive. You can also get info directly on your phone by texting HELP in response to the message you receive. Service will continue until customer cancels. </p>
    </div>
    <div>
        <h4>Email Opt-In</h4>
        <p> By providing UpMyLoan.com with your email address, you agree to receive transactional and promotional emails from UpMyLoan.com, its marketing partners, lenders and lender networks. </p>
    </div>
    <div>
        <h4>Loan Inquiry Policy</h4>
        <p> <span>UpMyLoan.com</span> reserves the right to reject your inquiry at any time for any reason not prohibited by law. UpMyLoan.com may require additional information or verification before your form is accepted. When you submit your information through a form on our Site, you will be connected with a lender or lending partner for further consideration based on the lender’s or lending partner’s policies and procedures. This does not mean your loan has been approved. Loan approval is solely the lender’s or lending partner’s decision. UpMyLoan.com is not responsible for taking, approving or denying your form. By submitting your information, you agree that if we cannot find a lender or lending partner that can offer you the type of loan you initially requested, we will search our network of lenders or lending partners to see if we can find other lenders or lending partners with different loan products to help you. </p>
    </div>
    <div>
        <h4>Credit References</h4>
        <p> Participating lenders or lending partners may verify your social security number, driver’s license number or other federal or state identification, as well as review your credit worthiness through national databases that may include Equifax, Transunion, Experian and other credit bureaus. By submitting your information to us, you agree that lenders or lending partners may obtain such credit reports and verify your information. </p>
    </div>
    <div>
        <h4>Restrictions</h4>
        <p> You must be 18 years or older and a legal resident of the United States to submit a form. You agree not to use this Site for any illegal or inappropriate activities. </p>
    </div>
    <div>
        <h4>Compliance with Laws</h4>
        <p> You agree to comply with all applicable laws, statutes, ordinances and regulations regarding your use of this Site and your acceptance or purchase of products or services through any of this Site’s third party vendors. <span>UpMyLoan.com</span> may, in its sole discretion, report actual or perceived violations of law to law enforcement or other authorities. If we become aware of any potential or suspected violation of these Terms of Use or our Privacy Policy, we may (but are not obligated to) conduct an investigation to determine the nature and extent of the suspected violation and the appropriate enforcement action to be taken. During such investigation, we may suspend services to any customer being investigated and may delete data from our servers in order to prevent or minimize further violations. You agree to cooperate fully with any such investigation. You acknowledge that violating the Terms of Use or Privacy Policy could subject you to criminal or civil penalties. </p>
    </div>
    <div>
        <h4>Intellectual Property</h4>
        <p> The trademarks, titles, characters, trade names, graphics, designs and other properties, including all software, text and images appearing on this Site is the intellectual property of <span>UpMyLoan.com</span>, its licensees, or other third parties who have authorized use of such properties on the Site. </p>
        <p> You have permission to view and make copies of documents, pages, images or other materials or content on this site for the sole purposes of requesting a loan. <span>UpMyLoan.com</span> reserves all other rights in this Site and its contents. You may not distribute, display, transmit or disseminate any documents, pages, images, materials or content without our prior written consent. Nothing contained on this Site should be construed as conferring any right or license to any trademark, copyright or other intellectual property right. </p>
    </div>
    <div>
        <h4>Limitation of Liability and Disclaimer of Warranties</h4>
        <p> <span>UpMyLoan.com</span> uses commercially reasonable efforts to ensure that the information on this Site is accurate, but accuracy is not guaranteed. <span>UpMyLoan.com</span> makes no representations regarding your use of this Site or results that you may or may not obtain through such use. </p>
        <p> NEITHER <span>UpMyLoan.com</span> NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING THIS SITE SHALL BE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS TO, OR USE OF, THIS SITE. EVERYTHING ON THIS SITE IS PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THESE EXCLUSIONS AND LIMITATIONS ARE APPLIED TO THE FULLEST EXTENT PERMITTED BY LAW. </p>
    </div>
    <div>
        <h4>Third Party Content</h4>
        <p> This Site may contain links to websites maintained by third parties. <span>UpMyLoan.com</span> is not responsible for the content or privacy policies of third party websites, and the existence of such links should not be considered an endorsement or recommendation of those websites, the product or service offered or any third parties associated with those websites. </p>
    </div>
    <div>
        <h4>Privacy</h4>
        <p> <span>UpMyLoan.com</span>’s Privacy Policy, which is incorporated herein by reference, is applicable to any data supplied through this Site. The Privacy Policy sets out your rights and our responsibilities regarding your personal information. <span>UpMyLoan.com</span> will not use your information in any way inconsistent with the purposes and limitations provided in the Privacy Policy. <span>UpMyLoan.com</span> reserves the right, in its sole discretion, to modify the Privacy Policy without prior notice to you. Modifications to the Privacy Policy are effective when posted and your continued use of this Site signifies your acceptance of such modifications. </p>
    </div>
    <div>
        <h4>Security</h4>
        <p> UpMyLoan.com uses industry-recognized technology to protect your personal information. Although UpMyLoan.com takes commercially reasonable measures to secure your personal information submitted through this Site, UpMyLoan.com cannot guarantee that your information will not be intercepted or decrypted by others. So long as UpMyLoan.com uses commercially reasonable security measures, it accepts no responsibility for such interception or decryption except as required by law. </p>
    </div>
    <div>
        <h4>Site Use</h4>
        <p> UpMyLoan.com does not charge you any fees for your use of this Site or our marketplace service. UpMyLoan.com is not acting as an agent for (1) you, (2) any lender or lending partner or (3) any supplier or vendor used in the evaluation of your loan inquiry or other use of this Site. UpMyLoan.com may receive compensation from lenders or lending partners and other third parties for its services. </p>
    </div>
    <div>
        <h4>Law and Venue</h4>
        <p> UpMyLoan.com is owned and operated by Customer Acquisition LLC, a Saint Kitts and Nevis limited liability company. By using this Site or engaging with UpMyLoan.com in any way, you consent to the laws and jurisdiction of Saint Kitts and Nevis. These Terms of Use and the Privacy Policy also will be governed by and construed in accordance with the laws of Saint Kitts and Nevis, without regard to conflict of law provisions. Any legal action brought against UpMyLoan.com (which must be via binding arbitration as set forth below) will be governed by the laws of Saint Kitts and Nevis and you agree that the sole jurisdiction and venue for disputes resolution will be in Saint Kitts and Nevis. </p>
    </div>
    <div>
        <h4>Binding Arbitration</h4>
        <p> Should any dispute arise as to the interpretation of any term or provision of these Terms of Use or with regard to any of your dealings UpMyLoan.com or with any lender or lending partner you borrow from as a result of UpMyLoan.com’s service, those issues shall be decided by binding arbitration. Arbitration proceeding shall be conducted under the applicable rules in Clark County, Nevada. The decision of the arbitrator shall be final and binding on both parties. The prevailing party shall be entitled to recover the cost of arbitration, travel expenses, expert testimony, travel expenses of experts, and all other expenses reasonably incurred in bringing or defending an arbitration claim, from the other party unless otherwise prohibited by law. </p>
    </div>
    <div>
        <h4>Modification</h4>
        <p> <span>UpMyLoan.com</span> reserves the right to modify these Terms of Use from time to time and without notice to you. Your use of this Site after such modification constitutes your acceptance of and agreement to any new or amended terms. </p>
    </div>
    <div>
        <h4>Indemnification</h4>
        <p> By using this Site, you agree to indemnify, defend and hold harmless <span>UpMyLoan.com</span>, its subsidiaries, affiliates, officers, agents and other partners and employees, from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use or attempted use of the Site. </p>
    </div>
    <div>
        <h4>Violation of the Terms of Use</h4>
        <p> By using the Site, you understand and agree that <span>UpMyLoan.com</span> in its sole discretion and without prior notice may terminate your access to this Site and to any services offered, and may remove any content you have provided if <span>UpMyLoan.com</span> believes that such content violates or is inconsistent with these Terms of Use or the Privacy Policy or their intent, or that such content may be disruptive, offensive or in violation of applicable laws. </p>
    </div>
    <div>
        <h4>Class Action Waiver</h4>
        <p> ANY PROCEEDINGS TO RESOLVE, LITIGATE OR ARBITRATE ANY DISPUTE IN ANY FORUM WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS. NEITHER YOU NOR UpMyLoan.com WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR IN ANY OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. NO ARBITRATION OR PROCEEDING WILL BE COMBINED WITH ANOTHER WITHOUT THE PRIOR WRITTEN CONSENT OF ALL PARTIES TO ALL AFFECTED ARBITRATIONS OR PROCEEDINGS. FURTHERMORE, YOU AGREE THAT YOU WILL NOT SEEK TO HAVE ANY DISPUTE AGAINST ANY LENDER OR LENDING PARTNER TO WHOM YOU ARE REFERRED HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR IN ANY OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. THIS CLASS ACTION WAIVER MAY BE LIMITED BY APPLICABLE LAW, BUT IS INTENDED TO BE ENFORCED TO THE FULL EXTENT ALLOWED BY LAW. </p>
    </div>
    <div>
        <h4>Miscellaneous</h4>
        <p> Any reference to UpMyLoan.com includes Customer Acquisition LLC. All rights not expressly granted to you are reserved to UpMyLoan.com. The headings used in these Terms of Use are intended for convenience only, and shall not affect the construction and interpretation thereof. UpMyLoan.com’s failure to insist upon or enforce strict performance of any provision of the Terms of Use shall not be construed as a waiver of such or any future provision or right. If any provision of these Terms of Use is held to be invalid or unenforceable, such determination shall not affect such provision in any other respect or any other provision of these Terms of Use, which shall remain in full force and effect. These Terms of Use and the Privacy Policy constitute the entire agreement and understanding between the parties with respect to the subject matter contained herein and supersedes and replaces any and all prior written or oral agreements related to the subject matter hereof. </p>
    </div>
    <div>
        <h4>Contact Information:</h4>
        <p> Company name: Customer Acquisition LLC <br/> Address: Springates Building, Lower Government Road, Charlestown, Saint Kitts and Nevis <br/> Email Address: <span>support@UpMyLoan.com</span> </p>
        <p><strong>INFORMATION FOR ISPs:</strong></p>
        <p> Website: www.<span>UpMyLoan.com</span><br/> Company name: Customer Acquisition LLC <br/> Email Address: <span>support@UpMyLoan.com</span> </p>
        <p> PLEASE NOTE THAT FOR QUESTIONS PERTAINING TO YOUR REQUEST STATUS, YOU MUST CONTACT YOUR LENDER OR LENDING PARTNER DIRECTLY. UpMyLoan.com IS NOT YOUR LENDER OR LENDING PARTNER AND CANNOT ACCESS INFORMATION ABOUT YOUR LOAN APPROVAL OR STATUS. </p>
        <p>Notice and Take Down Procedures</p>
        <p> If you believe any materials accessible on or from the Site infringe your copyright or other intellectual property, you may request removal of those materials (or access thereto) from the Site by contacting our copyright agent (identified below) and providing the following information: </p>
        <ol>
            <li> Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (for example, the URL) of an authorized version of the work. </li>
            <li> Identification of the material that you believe to be infringing and its location. Please describe the material, and provide a URL or any other pertinent information that will allow location of the material. </li>
            <li>Your name, address, telephone number and email address.</li>
            <li> A statement that in good faith you believe the complained of use of the materials is not allowed by the copyright owner, its agent, or the law. </li>
            <li> A statement that your submission is accurate and that "under penalty of perjury" you are the copyright owner or the owner’s authorized agent. </li>
            <li>A signature or the electronic equivalent from the copyright holder or authorized agent.</li>
        </ol>
        <p>Send the above information to:</p>
        <p> Legal Department <br/> Customer Acquisition LLC <br/> Springates Building, Lower Government Road, Charlestown, Saint Kitts and Nevis <br/> Email Address: <span>legal@UpMyLoan.com</span> </p>
        <p>For all email submissions, please include the subject line: DMCA Takedown Request.</p>
    </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
                  Get Started
                </a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
  )
}

export default TermsofService
