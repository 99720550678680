import React from 'react';

const HeadingSec = ({ heading, line1, line2 }) => {
  return ( 
    <div className='text-center'> 
        <h2 className='display-6 fw-bold'>{heading}</h2>
        <p className='p-0 m-0 display-6 fs-3'>{line1}</p>
        <p className='p-0 m-0 mb-3 display-6 fs-3'>{line2}</p> 
    </div>
  );
};

export default HeadingSec;
