
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>  

<h4>Rates and Fees</h4>
<p> 
UpMyLoan are not a lender, but offer a completely free service that connects you with suitable lenders who will accept your specific current situation and offer credit products to suit your needs. UpMyLoan are not a lender or lending partner, and does not provide any lending services directly to consumers.<br/><br/>
We honor the Truth in Lending Act, which requires full disclosure of all costs for any credit agreement, including loan fees and interest rates, that you will incur when using a specific product. When offered any loan from a lender or lending partner, ensure that they provide the complete cost at time of approval.
</p>
</div> 
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
