
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero' 
import TermsofService  from '../components/TermsofService'
import FooterSection from '../components/FooterSection'

const TermPage = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
     <TermsofService />
    <FooterSection/>
    </>
  )
}

export default TermPage
