import React from 'react'
import Button from 'react-bootstrap/Button';
const RequestLoan = () => {
  return (
    <div className='container'>
    <div className='request'>
      <div className='row'>
        <div className='col-md-6 col-12'>
         <h6 className='display-5 fs-3 m-0 p-0 textGreen'>Request a loan</h6>
         <h4 className='display-4 fs-1 m-0 p-0 text-white fw-bold'>In under 2 minutes!</h4> 
        </div>
        <div className='col-md-6 col-12'>
          <a href='/form'>
        <Button  size="lg" className='btnFill display-5 px-5 float-end' 
          >
           Get Started
          </Button>
          </a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default RequestLoan
