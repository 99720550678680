import React from 'react'
import HeadingSec from './haddingSec.js'

const RepExample = () => {
    const items = [
        {
          title: ' Example One ',
          desc: 'If you borrowed $5,000 over a 24 month period and the loan had a 6% APR, your monthly repayments would be $221.60, with a total payback amount of $5,318.47', 
         },
         {
          title: 'Example Two',
          desc: 'If you borrowed $5,000 over a 24 month period and the loan had a 15% APR, your monthly repayments would be $242.43, with a total payback amount of $5,818.40', 
         }  
      ];
  return (
    <>
    <div className='costMarg'>
    <div className='container'>
    <div className='text-center'>
       <HeadingSec
             heading="Representative Example" 
            
       />
    </div> 


    <div className='row'>
    {items.map((item, index) => (  
    <div key={index} className='col-md-6 col-12'> 
    <div className="stepBg text-center lending">  
    <h6 className="display-3 fs-5 fw-bold textHadd">{item.title}</h6>
    <p className="textPara">{item.desc}</p> 
</div> 
    </div>
 
     ))}
    </div>
    <div className='my-3 text-center'>
 <p className='fs-5'>
 The Annual Percentage Rate (APR) is the amount of interest you would pay on a personal loan over the
course of a full year. The cost of the loan and APR depend on several factors, including the lender and/or lending partner,
credit history, employment history, and applicable law. Your APR also depends on the desired loan amount
and length of the repayment period. 
 </p>

 <p className='fs-5'>
 The terms of your loan, including the APR, fees, finance charges, and other terms are disclosed during
the loan request process after you are directed to the lender's and/or lending partner's loan agreement. This information
is strictly between you and the lender and/or lending partner you are connected with. Not all customers will be
eligible for a loan or meet the criteria to receive the best terms or lowest interest rate.
 </p>

 <p className='fs-5'>
 The examples represent sample rates, are for informational purposes only, 
and may not reflect the actual APRs offered by your lender and/or lending partner.
 </p>
 </div>

    </div>
    </div>
    </>
  )
}

export default RepExample
