
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
      <h4>Full Disclosure of Terms</h4>
      <p> 
      We do not offer any finance or credit agreements, all offers will be directly to you from lenders and lending partners. Please ensure that all documentation complies with the Truth in Lending Act and other legislation, and that you fully understand the implications of the credit being offered before signing any agreement. Each offer will be accompanied by full disclosure of terms, and you are under no obligation to accept any offer for any reason. If you do accept an offer, you can sign the e-page of the document and your funds will be processed by the lender.<br/><br/>
UpMyLoan.com have no influence or knowledge of any offers made to you from lenders or lending partners, and any credit agreement is solely between you and the lender you choose.
</p>
   </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
