import React from 'react'

const WhyChooseContent = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
        <p className='lh-base fs-5 fw-medium display-4'>
        Our Bad Credit Loans Service provides fast, easy access to the credit you need, no fuss, no hassle, no hidden charges.<br/><br/>
We offer the widest choice of bad credit loan providers, with no upfront fees, a simple 2 minute application process and fast payout on approval. Whatever your credit score, we can find a loan to meet your needs, all in just a couple of minutes.<br/><br/>
With loans paid into your bank, often as quickly as next day, whatever the situation, if you need money fast, regardless of your credit score, just apply today. <br/><br/>
Our system uses cutting edge security, and with a range of flexible loan terms available, there is a solution to your credit needs just a few minutes away.<br/><br/>

        </p>
    </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
                  Get Started
                </a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
  )
}

export default WhyChooseContent
