
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero' 
import SellInfo  from '../components/SellInfo'
import FooterSection from '../components/FooterSection'

const PersonalInformation = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=""/>  */}
     <SellInfo />
    <FooterSection/>
    </>
  )
}

export default PersonalInformation
