
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'
import PolicyPage from '../components/PolicyPage'

const PrivacypolicyPage = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
     <PolicyPage />
    <FooterSection/>
    </>
  )
}

export default PrivacypolicyPage
