import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../src/pages/HomePage"
import AboutPage from "../src/pages/AboutPage"
import ContactPage from "../src/pages/ContactPage"
import PrivacypolicyPage from "../src/pages/PrivacypolicyPage"
import AdvertiserDisclosure from "../src/pages/AdvertiserDisclosure"
import PersonalloanNotice from "../src/pages/PersonalloanNotice"
import PersonalInformation from "../src/pages/PersonalInformation"
import FormPage from "../src/form/FormPage"
import TermPage from "../src/pages/TermPage"
import DefinationPage from "../src/pages/DefinationPage"
import WhyChooseus from "../src/pages/WhyChooseus"

import RatesandFees from "../src/pages/RatesandFees"
import FullDisclosureofTerms from "../src/pages/FullDisclosureofTerms"
import LatePaymentPolicy from "../src/pages/LatePaymentPolicy"
import NonPaymentPolicy from "../src/pages/NonPaymentPolicy"
import LoanRenewalPolicy from "../src/pages/LoanRenewalPolicy"
function App() { 
  return (
    <BrowserRouter> 
    <Routes>  
     {/* <Route path="dashboard" element={<DashboardIndex />}/> 
      <Route path="add-event" element={<AddEventPage />}/> 
      <Route path="add-event-race" element={<EventRace />}/>  */}
        <Route path="*" element={<HomePage />} /> 
        <Route path="/contactus" element={<ContactPage />} /> 
        <Route path="/aboutus" element={<AboutPage />} /> 
        <Route path="/form" element={<FormPage />} /> 
        <Route path="/termsofservice" element={<TermPage />} /> 
        <Route path="/privacypolicy" element={<PrivacypolicyPage />} /> 
        <Route path="/disclaimer" element={<AdvertiserDisclosure />} /> 
        <Route path="/econsent" element={<PersonalloanNotice />} /> 
        <Route path="/lendingpolicy" element={<PersonalInformation />} /> 
        <Route path="/definition" element={<DefinationPage />} /> 
        <Route path="/ratesandfees" element={<RatesandFees />} /> 
        <Route path="/fulldisclosureofterms" element={<FullDisclosureofTerms />} /> 
        <Route path="/latepaymentpolicy" element={<LatePaymentPolicy />} /> 
        <Route path="/nonpaymentpolicy" element={<NonPaymentPolicy />} /> 
        <Route path="/loanrenewalpolicy" element={<LoanRenewalPolicy />} /> 
        <Route path="/whychooseus" element={<WhyChooseus />} /> 


 
    </Routes>
  </BrowserRouter>
  );
}

export default App;
