import React from 'react' 
const SellInfo = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div className="upmyloan">
   {/* <h4 className="page_title">Policy on Responsible Lending</h4> */}

   <p className='lh-lg'>
<strong>ACH</strong> – Automatic Clearning House, themethod used to transfer funds to and from bank accounts.<br/>
<strong>APR or Annual Percentage Rate</strong> – How much, per year, you pay in interest.<br/>
<strong>Balance</strong> - The amount outstanding in your account.<br/>
<strong>Bankruptcy</strong> – A legal definition for someone who is unable to pay off their debts.<br/>
<strong>Budget</strong> – A plan for matching your incoming earnings to your outgoing expenditure.<br/>
<strong>Caps</strong> – Limits to the level of  interest rate that can be applied to an adjustable rate mortgage loan.<br/>
<strong>Cash advance</strong> – An emergency access to cash in the form of a loan for people with poor credit access. It is designed to bridge the gap when there is a shortfall of funds until the next payday, and are often called payday loans.<br/>
<strong>Charge off</strong> – A loan that is deemed uncollectible from a borrower.<br/>
<strong>Checking account</strong> – A standard bank account accessible via a checkbook or ATM card..<br/>
<strong>Compound interest</strong> – Interest that accumulates over time, compounding as interest accrues on the previous interest and so on.<br/>
<strong>Credit</strong> - The promise to pay at a later date for services or goods.<br/>
<strong>Credit bureau</strong> – The organization that provides credit scores, that assesses your suitability for a loan or other line of credit.<br/>
<strong>Credit card</strong> – A form of rolling credit that accrues interest on the outstanding balance each month.<br/>
<strong>Credit report</strong> – A file containing the the history of a person's debt repayment, including the total outstanding debt they currently have, any bankruptcies they may have declared and late payments made on current or previous loans. <br/>
<strong>Credit request</strong> - A request for credit given in writing. This can incur a fee in some situations.<br/>
<strong>Creditor</strong> – A business or individual you owe money to, either as a loan or other type of credit.<br/>
<strong>Debit card</strong> – A payment card that, unlike a credit card, takes the entire sum charged straight from your bank account immediately.<br/>
<strong>Debt</strong> – Money owed to a lender or lending partner.<br/>
<strong>Debt Consolidation</strong> – A strategy to bring multiple loans together into one single loan and payment, the goal being reducing the overall payment made compared to multiple individual loans.<br/>
<strong>Default</strong> – A failure to make agreed loan payments.<br/>
<strong>Delinquency</strong> - Failing to pay on time.
<strong>Direct Deposit</strong> – An electronic transfer of funds directly into your bank account.<br/>
<strong>Equal Credit Opportunity Act</strong> - This is a federal law which prohibits lenders and lending partners from any sort of applicant discrimination.<br/>
<strong>E-Signature</strong> – A legally mandated electronic signature system, this requires software which integrates your signature into a document.<br/>
<strong>FDIC or Federal Deposit Insurance Corporation</strong> – Federal backed insurance covering consumer's deposit in their savings and for a loan account of up to $100,000 for each individual account. <br/>
<strong>Finance charge</strong> – How much a credit agreement costs in dollars.<br/>
<strong>Fixed interest rate</strong> - A rate of interest which will not change through the term of the loan.<br/>
<strong>Foreclosure</strong> - This is a legal process in which collateral that has been pledged for a loan can be sold in order to repay the loan if the borrower defaults.<br/>
<strong>Installment loan </strong>- This is a loan that has a predetermined number of payments and loan amount.<br/>
<strong>Interest</strong> - A fee that the lender or lending partner charges for borrowing a sum of money.<br/>
<strong>Interest rate</strong> – How much the loan costs per year as a percentage of the outstanding debt.<br/>
<strong>Late payment fee</strong> - A charge for a payment which is not received on the agreed due date.<br/>
<strong>Lease</strong> - A legal contract which allows the leasee to use an asset in exchange for agreed, regular payments. The asses, such as a vehicle, machinery and so on, would be returned to the lessor at the end of the agreed term.<br/>
<strong>Lender or Lending Partner</strong> – An organization or individual that offers loans either directly or as an intermediary.<br/>
<strong>Liable</strong> - Having legal responsibility for something.<br/>
<strong>Lien</strong> – A situation where a creditor claims ownership of part or all of a property to ensure an outstanding debt is paid off.<br/>
<strong>Loan</strong> - Any amount which is borrowed and then paid back over time, usually with interest.<br/>
<strong>Loan Agreement</strong> - A legal contract defining the terms of a loan.<br/>
<strong>Mortgage loan</strong> – A loan used to purchase real estate. The property acts as security for the loan.<br/>
<strong>Repossess</strong> – Assets that are taken by the lender to cover outstanding debt in situations where someone has not been able to or refuses to repay the debt.<br/>
<strong>Right of recession</strong> - The right of a borrower to cancel the contract within just three working days.<br/>
<strong>Secured loan</strong> - This is a loan where the loan is secured against an asset such as a vehicle or property, which will be sized in the event of a default on the loan.<br/>
<strong>Security</strong> – Collateral for a check or other payment.<br/>
<strong>Security or Collateral</strong> - An asset which is pledged against a debt to ensure repayment.<br/>
<strong>The Fair Credit Reporting Act</strong> - This is a federal law which gives borrowers the right to know the exact information credit reporting agencies currently hold about them. <br/>
<strong>Title</strong> - A document which proves ownership of property.<br/>
<strong>Truth in Lending Act</strong> – A federal law that requires lenders to make clear the full cost of any credit they offer. <br/>
<strong>Unsecured loan</strong> – A loan offered without the need for security of any kind.<br/>
<strong>Variable interest rate </strong>– An interest rate that can change over the course of a loan.<br/>
<strong>Yield</strong> – The amount of money that can be made from an asset. <br/>

</p> 



 



 

   






</div> 
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
  )
}

export default SellInfo
