import React from 'react'

const AdverDisc = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
   <h4>Disclaimer</h4>
   <div>
      <p><strong>Important Disclosures. Please Read Carefully.</strong></p>
      <p>Persons facing serious financial difficulties should consider other alternatives or should seek out professional financial advice.</p>
      <p>This website uses Site Visit Recordation Technology to memorialize site visits, including technology provided by third parties like Jornaya’s LeadiD. By submitting your information through this website, you consent to the use of Site Visit Recordation Technology.</p>
      <p>The owner of this website is not a lender or agent of any lender and does not take loan applications or otherwise engage in lender-related activity (including without limitation making credit decisions or arranging, brokering, originating, servicing, underwriting, funding, or collecting payments for any lender). Submitting your information via this website is a request to be connected with one or more financial service providers that may be able to help you with your financial needs, each being responsible for taking a credit application, verifying your information, and making their own credit decisions.</p>
      <p>The services offered by this website are administrative only (frequently referred to as “lead generation”) and are offered to you free of charge. Lenders and other financial service providers pay a fee to be connected to consumers in search of financial services, often based on a ping tree model similar to Google AdWords where the highest available bidder is connected to the consumer.</p>
      <p>In some cases, you may be given the option of being connected to a tribal lending enterprise (“TLE”). TLEs are subject to tribal and certain federal laws while being immune from state law including usury caps. If you are connected to a TLE, please understand that the tribal lender’s rates and fees may be higher than state-licensed lenders. Additionally, TLEs may require you to agree to dispute resolution in a tribal jurisdiction. You should read and understand the terms of any loan offered by any lender, whether tribal or state-licensed, and to reject any loan offer that you cannot afford to repay or that includes terms that are not acceptable to you.</p>
      <p>By submitting your information via this website, you authorize the owner of this website and its network of available lenders to do a credit check, which may include verifying your social security number, driver license number, or other identification, and a review of your creditworthiness. Credit checks are usually performed by one of the major credit bureaus such as Experian, Equifax and TransUnion, but also may include alternative credit bureaus such as Clarity, DataX, or others. You also authorize the owner of this website to share your information and credit history with its network of available lenders and other service providers.</p>
      <p>Our lenders offer loans with an Annual Percentage Rate (APR) of 35.99% and below. For qualified consumers, the maximum APR (including the interest rates plus fees and other costs) is 35.99%. All loans are subject to the lender’s approval based on its own unique underwriting criteria.</p>
      <p>Example: Loan Amount: $4,300.00, Annual Percentage Rate: 35.99%. Number of Monthly Payments: 30. Monthly Payment Amount: $219.36. Total Amount Payable: $6,581.78 Loans include a minimum repayment plan of 12 months and a maximum repayment plan of 30 months.</p>
      <p>This service is not available in all states.</p>
      <p><strong>Lender’s or Lending Partner’s Disclosure of Terms.</strong></p>
      <p>When a lender decides to offer you a loan, it is required by law to provide documents that contain all fees and rate information pertaining to that loan, including any potential fees for late-payments, and the terms (if permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and interest rates are determined solely by the lender or financial service provider based on their internal policies, underwriting criteria and applicable law. This website has no knowledge of or control over the loan terms offered to you.</p>
      <p><strong>Late Payments Hurt Your Credit Score</strong></p>
      <p>Please be aware that missing a payment or making a late payment can negatively impact your credit score. To protect yourself and your credit history, make sure you only accept loan terms that you can afford to repay. If you cannot make a payment on time, you should contact your lender immediately and discuss payment options.</p>
   </div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
  )
}

export default AdverDisc
