
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
      <h4>Late Payment Policy</h4>
      <p> 
      

It is important that you review the late payment policy of any loan agreement before signing. Lenders do differ, so always check the detail of each and ensure you comply with all terms and conditions. Remember, lenders are obliged to follow all legal requirements within their terms and conditions.
</p>
   </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
