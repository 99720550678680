
import React from 'react' 
import NavBar from "../components/Navbar"
import AboutHero from '../components/AboutHero' 
import ContactSection from '../components/ContactSection' 
import FooterSection from '../components/FooterSection'

const HomePage = () => {  
 
  return ( 
    <>
    <NavBar/>
    <AboutHero head="Contact Us"/> 
    <ContactSection/>
    <FooterSection/>
    </>
  )
}

export default HomePage
