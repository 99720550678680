import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import HeadingSec from './haddingSec.js'

const LatestBlogs = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://blog.upmyloan.com/wp-json/wp/v2/posts")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data); // Add this line
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setLoading(false);
      });
  }, []);

  const decodeEntities = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

 
  return (
    <section id="blog-section" className="container pb-5">
    <h1 className="fw-semibold fs-2 text-center classHadding">
       
    </h1>
    <HeadingSec
             heading="Latest from our blogs" 
            
       />
    <div className="row g-4 mt-3">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Swiper
          className="mt-4 px-4"
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 5000,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="col-12 col-md-12">
                <a href={item.link} className="blog-title text-decoration-none" target="_blank" rel="noopener noreferrer">
                  <img height={300}
                    src={item.jetpack_featured_media_url}
                    className="blog-thumbnail img-fluid rounded-4"
                    alt={`Post ${i}`}
                  />
                </a>
                <div className="d-flex justify-content-between mt-3">
                  <p className="m-0 text-primary text-opacity-75 small fw-semibold ">
                    {/* <img
                      className="pe-1"
                      src={CalenderIcon}
                      style={{ height: "1.2rem" }}
                      alt="Calendar Icon"
                    /> */}
                    <span className="align-middle">
                      {formatDate(item.date)}
                    </span>
                  </p>
                  {/* Add User information here if available */}
                </div>
                <h4 className="textHadd fs-5 fw-semibold mt-2">
                  <a href={item.link} className="blog-title classHadding text-decoration-none textHadd fw-bold" target="_blank" rel="noopener noreferrer">
                    <span dangerouslySetInnerHTML={{ __html: decodeEntities(item.title.rendered) }} />
                  </a>
                </h4>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  </section>
  );
};

export default LatestBlogs;
