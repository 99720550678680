import React from 'react'

const PolicyPage = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div>
    <p>The purpose of the UpMyLoan.com Privacy Policy is to provide you with a comprehensive description of UpMyLoan.com’s online and offline practices regarding the collection, use, disclosure, and sale of personal information and of the rights of consumers regarding their personal information.</p>
    <h4>Collection of Personal Information</h4>
    <p>We collect the following personal information about consumers:</p>

    <table className="table">
        <tbody>
            <tr>
                <th>Category</th>
                <th>Categories of sources from which the information was collected</th>
                <th>The business or commercial purpose for which the information was collected</th>
            </tr>
            <tr>
                <td>Identifiers such as a real name, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, social security number, and driver’s license number.</td>
                <td>Consumers directly</td>
                <td>To connect consumers with a lender and offer other services they may be interested in.</td>
            </tr>
            <tr>
                <td>Categories of personal information* such as name, social security number, address, telephone number, driver's license or state identification card number, employment, bank account number, and other financial information.</td>
                <td>Consumers directly</td>
                <td>To connect consumers with a lender and offer other services they may be interested in.</td>
            </tr>
            <tr>
                <td>Characteristics of protected classifications under federal law and applicable state law, such as age.</td>
                <td>Consumers directly</td>
                <td>To connect consumers with a lender and offer other services they may be interested in.</td>
            </tr>
            <tr>
                <td>Geolocation data such as your approximate location.</td>
                <td>Data analytics providers</td>
                <td>To connect consumers with a lender and offer other services they may be interested in.</td>
            </tr>
        </tbody>
    </table>
    <p>*as described in subdivision (e) of California Civil Code Section 1798.80</p>
    <h4>Disclosure or Sale of Personal Information</h4>
    <p>We disclose or sell the following categories of personal information to third parties for a business or commercial purpose:</p>
    <table>
        <tbody>
            <tr>
                <th>Category</th>
                <th>The categories of third parties to whom the information was disclosed or sold</th>
            </tr>
            <tr>
                <td>Identifiers such as a real name, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, social security number, and driver’s license number.</td>
                <td>Lenders, lender-networks, and other marketing partners.</td>
            </tr>
            <tr>
                <td>Categories of personal information* such as name, social security number, address, telephone number, driver's license or state identification card number, employment, bank account number, and other financial information.</td>
                <td>Lenders, lender-networks, and other marketing partners.</td>
            </tr>
            <tr>
                <td>Characteristics of protected classifications under California or federal law such as age.</td>
                <td>Lenders, lender-networks, and other marketing partners.</td>
            </tr>
        </tbody>
    </table>
    <p>*as described in subdivision (e) of California Civil Code Section 1798.80</p>
    <p>We do not collect or sell the personal information of minors we know to be under 16 years of age without affirmative authorization.</p>
    <p>Your email could be shared with one of our preferred mailing partners and other brands believed to be of interest to you for the purpose of sending third party promotional email communication. You may unsubscribe from any such emails at any time.</p>
    <h4>Security</h4>
    <p>To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings. Wherever we collect sensitive information, that information is encrypted and transmitted to us in a secure way.</p>
    <h4>Cookies</h4>
    <p>We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site.</p>
    <h4>Limiting Sharing</h4>
    <p>To limit our sharing, email us at support@UpMyLoan.com.</p>
    <h4>Contact for More Information</h4>
    <p>support@UpMyLoan.com</p>
    <h2>For California Consumers</h2>
    <p>If you are a California consumer as defined by the California Consumer Protection Act (“CCPA”) you have the following rights:</p>
    <h4>1. Right to Know About Personal Information Collected, Disclosed, or Sold</h4>
    <p>You have the right to request that UpMyLoan.com disclose the personal information we collect, use, disclose, and sell by sending a request to support@UpMyLoan.com or by clicking <a data-ccpa="accessInfo" href="/">here</a>. For categories of information collected or disclosed, please refer to the information provided above, which reflects the categories of information collected or disclosed in the last 12 months.</p>
    <p>In order to verify your request for information, you must provide your name, email, and phone number. A verification link will be sent to your email address. It will direct you to a verification page where you must provide your name, email address, date of birth, driver’s license number or state ID number, and Social Security number. We will match the personal information provided by you with personal information maintained by UpMyLoan.com. You will also have to submit a signed declaration under penalty of perjury that you are the consumer whose personal information is the subject of the request. Once your identity is verified, your request will be processed in a timely manner.</p>
    <h4>2. Right to Request Deletion of Personal Information</h4>
    <p>You have the right to request deletion of your personal information collected or maintained by sending a request to support@UpMyLoan.com or by clicking <a data-ccpa="accessInfo" href="/">here</a>.</p>
    <p>In order to verify your request for deletion, you must provide your name, email, and phone number. A verification link will be sent to your email address. It will direct you to a verification page where you must provide your name, email address, date of birth, driver’s license number or state ID number, and Social Security number. We will match the personal information provided by you with personal information maintained by UpMyLoan.com. You will also have to submit a signed declaration under penalty of perjury that you are the consumer whose personal information is the subject of the request. Once your identity is verified, your request will be processed in a timely manner.</p>
    <h4>3. Right to Opt-Out of the Sale of Personal Information</h4>
    <p>You have the right to opt-out of the sale of your personal information.</p>
    <p>You can direct UpMyLoan.com to stop selling your personal information, and to refrain from doing so in the future, by clicking <a data-ccpa="doNotSell" href="/">here</a> or sending a request to support@UpMyLoan.com.</p>
    <p>If you are an Authorized Agent acting on behalf of a consumer to exercise their right to opt-out, send an email request to support@UpMyLoan.com. You will be required to submit documentation as proof of the agency relationship.</p>
    <h4>4. Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</h4>
    <p>You have the right to receive nondiscriminatory treatment by us for the exercise of your privacy rights conferred by the CCPA.</p>
    <p>Unless permitted by the CCPA, UpMyLoan.com will not:</p>
    <ul>
        <li>Deny you goods or services</li>
        <li>Charge different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties.</li>
        <li>Provide you a different level or quality or services if you exercise your rights under the CCPA.</li>
        <li>Suggest that you will receive a different price or rate for goods or services or a different level of quality of goods or services.</li>
    </ul>
    <h4>5. Authorized Agent Requests on Behalf of a Consumer</h4>
    <p>You have the right to designate an authorized agent to make a request under the CCPA on your behalf. To make a request on behalf of a consumer as an authorized agent, please send an email request to support@UpMyLoan.com. You will be required to provide documentation that proves the agency relationship.</p>
    <p>Last updated: July May, 2024</p>
</div>

            
    </div>
    </div>
    </div>
  )
}

export default PolicyPage
