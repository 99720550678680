import React from 'react'

const ImportantBar = () => {
  return (
    <div className='bgCostoGreen text-center'>
    <div className='container'>
      <h6 className='textGreen display-2 fs-2 fw-bold'>Our goal at UpMyLoan is to<br/> provide access to personal loans, car loans,<br/> at a highly competitive interest rate</h6>
    </div>
    </div>
  )
}

export default ImportantBar
