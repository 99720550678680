import React from 'react' 
const SellInfo = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div className="upmyloan">
   {/* <h4 className="page_title">Policy on Responsible Lending</h4> */}
   <div>
      <h4>State Regulations</h4>
      <p> 
      All lenders and lending partners must abide by any federal, state or local laws that apply to any specific agreement. This could include how many loans, how often loans can be awarded, interest rate limits, the legality of rollovers, fee limits and more. Before accepting any loan offer, you should ensure that the agreement is compliant and that you fully understand your obligations and consequences of late or failed payments.
 </p>
   </div>
   <div>
      <h4>TILA- The Truth in Lending Act</h4>
      <p> 
      This federal regulation means that lenders and lending partners must provide, in writing, the exact costs incurred with any loan agreement. That includes fees, interest and so on, and should be provided before any agreement is signed. 
 </p>
   </div>
   <div>
      <h4>Dodd-Frank Wall Street Reform Act</h4>
      <p> 
      Failr lending is essential, and the framework for that is dictated by the Dodd-Frank act. Regulations provided and enforced by the Consumer Financial Protection Bureau, CFPB, aim to prevent disparities between consumers of different ethnicities, genders or ages.
 </p>
   </div>
   <div>
      <h4>Disclosure of Personal Information</h4>
      <p> 
      To comply with federal law, lenders and lending partners must know who they are working with when signing any loan agreement. The purpose of this initiative is to combat money laundering and funding of various criminal activities. As a result, you will be asked to provide a range of personal information including name, date of birth, address, your social security number and other information, and in some cases will also need to provide valid state or federal ID to verify that information.
</p>
   </div>

   






</div>

</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
                  Get Started
                </a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
  )
}

export default SellInfo
