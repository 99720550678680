import React from 'react'
import One from "../assets/img/1lending.png"
import Two from "../assets/img/2lending.png"
import Three from "../assets/img/3lending.png"
import Four from "../assets/img/4lending.png"
import HeadingSec from './haddingSec.js'

const BadLending = () => {
  const items = [
    {
      title: 'Hundreds of Lenders',
      desc: 'Access our national network of lenders with one simple application to find loans tailored to your needs.',
      img: One,
     },
     {
      title: 'Fast Payouts',
      desc: 'With easy applications and direct deposits, your loan could be in your account in just 24 hours.',
      img: Two,
     },
     {
      title: 'Secure Application',
      desc: 'We employ state-of-the-art encryption protocols to ensure the utmost security and protection of your valuable data',
      img: Three,
     },
     {
      title: 'Loans for Everyone',
      desc: 'No matter your credit score, we can find loans designed for you. Apply today and enjoy flexible terms that suit your needs.',
      img: Four,
     }
  ];
  
  return (
    <>
    <div className='costMarg'>
    <div className='container'>
    <div className='text-center'>
       <HeadingSec
             heading="Why Choose UpMyLoan ?"
             line1="Choosing UpMyLoan for bad credit ensures accessibility and support"
             line2="when traditional avenues may turn you away." 
            
       />
    </div>  
    <div className='row '> 
    {items.map((item, index) => ( 
    <div key={index} className='col-md-6 col-12'> 
    <div className="d-flex stepBg" >
  <img  src={item.img} alt={item.title} width={60} height={60} className='m-2' />
  <div className="card-body m-3 lending">
    <h6 className="display-3 fs-5 fw-bold textHadd">{item.title}</h6>
    <p className="textPara">{item.desc}</p>
  </div>
</div> 
    </div>
     ))}
    </div>
    </div>
    </div>
     </>
  )
}

export default BadLending
