
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero' 
import AdverDisc  from '../components/AdverDisc'
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
     <AdverDisc />
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
