
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero' 
import DefinationContent  from '../components/DefinationContent'
import FooterSection from '../components/FooterSection'

const DefinationPage = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=""/>  */}
     <DefinationContent />
    <FooterSection/>
    </>
  )
}

export default DefinationPage
