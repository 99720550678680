// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import Offcanvas from 'react-bootstrap/Offcanvas';    
import NavLogo from "../assets/img/upmyloan.png" 
import NavLogodrk from "../assets/img/upmyloanblk.png" 

function NavbarDark() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="darkvanBg mb-3">
          <Container className='d-flex' fluid> 
            <Navbar.Brand href="/"><img src={NavLogo} width={100} alt='upmyloan'/></Navbar.Brand>
            <div className='d-flex justify-content-end'>
            <p className='text-white fw-bold fs-4 my-2 d-none d-sm-block mx-2'>Request a loan in under 2 minutes!</p> 
            {/* <Button variant="outline-secondary mx-3 btnBrdrclr d-none d-sm-block">Get Started</Button>  */}
            <Navbar.Toggle className='me-0 bg-white px-2' aria-controls={`offcanvasNavbar-expand-${expand}`} />  
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={NavLogodrk} width={100} alt='upmyloan'/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/aboutus">About Us</Nav.Link>
                  <Nav.Link href="/contactus">Contact Us</Nav.Link>
                  <Nav.Link href="https://blog.upmyloan.com">Blogs</Nav.Link>  
                  <Nav.Link href="/whychooseus">Why Choose Us</Nav.Link>
                  <Nav.Link href="/termsofservice">Terms of Service </Nav.Link>
                  <Nav.Link href="/privacypolicy">Privacy Policy </Nav.Link>
                  <Nav.Link href="/disclaimer">Disclaimer </Nav.Link>
                  <Nav.Link href="/personalloannotice">Personal Loan Notice </Nav.Link>
                  <Nav.Link href="/definition">Definition</Nav.Link> 
                  <Nav.Link href="/ratesandfees">Rates and Fees</Nav.Link> 
                   <Nav.Link href="/fulldisclosureofterms">Full Disclosure of Terms</Nav.Link> 
                   <Nav.Link href="/latepaymentpolicy">Late Payment Policy</Nav.Link> 
                   <Nav.Link href="/nonpaymentpolicy">Non-Payment Policy</Nav.Link>  
                  <Nav.Link href="/lendingpolicy">Lending Policy</Nav.Link>
                   
                </Nav>
  
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavbarDark;