import React from 'react'
import abt1 from "../assets/img/abt1.jpg"
import abt2 from "../assets/img/abt2.jpg"

const AboutImg = () => {
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-4 col-md-4 col-12'>
      <img src={abt1} className='img-fluid' alt='About Us'/>
    </div>
    <div className='col-lg-8 col-md-8 col-12'>
    <img src={abt2} className='img-fluid' alt='About Us'/>
      </div>
    </div>
<p className='textPara fs-5 display-4 fw-medium text-center mt-4'>
Experts in bad credit loans, we work on your behalf to find the best option for any loan you need between $100 and $5,000 value.<br/><br/>
We understand how difficult it can be to find the funds you need, but when time matters, no matter what your credit score, we offer an easy application, fast decisions and quick payouts. We set out to make it easier to access the loan you need, and our simple process takes the stress out of applications for a smooth, hassle-free experience.<br/><br/>
If you need a loan for bad credit, we are here to help. From the application process to our friendly support team, everything is in place to make things easy for you. We take your data seriously of course, with the latest in security technology keeping your information safe and ensuring you can apply with confidence.<br/><br/>
You can have the loan you need, no matter what your credit score is like, just apply today.


</p>

    </div>
    </div>
  )
}

export default AboutImg
