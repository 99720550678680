
import React from 'react' 
import NavBar from "../components/Navbar"
import HeroSection from '../components/HeroSection'
import AboutStep from '../components/AboutStep'
import BadLending from '../components/BadLending'
import FaqSection from '../components/FaqSection'
import RepExample from '../components/RepExample'
import FooterSection from '../components/FooterSection'
import One from "../assets/img/one.png"
import Two from "../assets/img/two.png"
import Three from "../assets/img/three.png" 
import LatestBlogs from '../components/LatestBlogs'

const HomePage = () => { 
  const items = [
    {
      title: 'Take 2 minutes out of your day to fill out our simple loan form',
      imgUrl: One,
    },
    {
      title: 'Once approved, review the loan agreement and authorize the loan',
      imgUrl: Two
    },
    {
      title: 'Funds can be transferred straight to your bank as quickly as the next day',
      imgUrl: Three
    }
  ];
  const headerContent = (
    <div>
      <h6 className='textPara display-6 fs-4'>How it Works</h6>
      
      <h4 className='textHadd display-5 fw-bold'> Flexible, Fast, loans <span className='textGreen'>whatever your <br/>credit score,</span> in just 3 steps:</h4>
      <p className=''> If you are facing unplanned bills or other financial challenges, fast access to credit can make all the difference. </p>
      <p>
        With our superfast loan service, you can find the funds you need even if you have bad credit.</p> 


      <p>With no upfront fee and no report on your credit score, you can apply in seconds and have your money as early as tomorrow.</p>
     <p><strong>It really is as easy as 1,2,3 to get the bad credit loan you need.</strong></p> 

    </div>
  );
  return ( 
    <>
    <NavBar/>
    <HeroSection/>
    <AboutStep items={items} headerContent={headerContent} />
    <BadLending/>
    <FaqSection/>
    <RepExample/>
  <LatestBlogs/>
    <FooterSection/>
    </>
  )
}

export default HomePage
