import React from 'react'
import HeroSlogan from '../components/HeroSlogan';
const HeroSection = () => {
    const heroData = [
        {
          title: (<span>Fast, Hassle-Free Loans For<br/>  Whatever You Need.</span>),
          description: 'Loans from $100 to $5,000 with Next Day Payout',
          descriptionTwo: 'No Credit? Bad Credit? No Problem!',
          buttonText: 'Get Started Now',
          note: (<span>This service is FREE and will NOT affect<br/>your credit score</span>) 
        } 
        // Add more data objects as needed
      ];

  return (
    <div className='bnrBg'>
    <div className='container'>
         {heroData.map((data, index) => (
        <HeroSlogan key={index} data={data} />
      ))}
    </div>
    </div>
  )
}

export default HeroSection
