
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
      <h4>Loan Renewal Policy</h4>
      <p> 
      State legislation dictates what kind of loan renewal is available and how it is operated. The availability of repayment term extensions or rollovers will depend on location, and where allowed it is important to understand that using these facilities can add substantial costs to the overall loan.
</p>
   </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
