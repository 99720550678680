import React, { useState } from "react";
import faqs from "../data/faqs.json";
import Button from 'react-bootstrap/Button';
import HeadingSec from './haddingSec.js' 

const FaqSection = () => {
  const [faqsToShow, setFaqsToShow] = useState(faqs.slice(0, 6));

  const loadMoreFaqs = () => {
    if (faqsToShow.length >= faqs.length) return;
    setFaqsToShow([...faqsToShow, ...faqs.slice(faqsToShow.length, faqsToShow.length + 6)]);
  };

  return (
    <>
      <div className='costMarg'>
        <div className='container'>
          <div className='text-center'>
            <HeadingSec
              heading="Frequently Asked Questions" 
            />
          </div>  
   
          <div className="accordion mt-4" id="faq-accordion">
            {faqsToShow.map((item, i) => (
              <div key={i} className="accordion-item my-3">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed rounded fw-bold fs-6 textHadd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapse" + i}
                    aria-expanded="false"
                    aria-controls={"collapse" + i}
                  >
                    {item.qus}
                  </button>
                </h2>
                <div id={"collapse" + i} className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body fs-5 textPara">
                    {/* Render each line of the answer separately */}
                    {item.ans.split('\n').map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div> 
                </div>
              </div>
            ))}
          </div>

          {faqs.length > faqsToShow.length && (
            <div className="text-center">
              <Button
                onClick={loadMoreFaqs}
                size="lg"
                className='btnFill display-5 px-5' 
              >
                LOAD MORE
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FaqSection;
