
import React from 'react' 
import NavBar from "../components/Navbar"
import AboutHero from '../components/AboutHero'
import AboutStep from '../components/AboutStep'
import ImportantBar from '../components/ImportantBar'
import FaqSection from '../components/FaqSection'
import RepExample from '../components/RepExample'
import FooterSection from '../components/FooterSection'
import AboutImg from '../components/AboutImg'

const AboutPage = () => { 
  const customItems = [
    {
      title: 'Multiple Loan Options', 
      desc: 'Various versions have evolved over the years,sometimes by accident.'
    },
    {
      title: 'Competitive Rate', 
      desc: 'Nam enim semex eli aliquet nec lorem ipsum pretiumlacinia eget elit.'
    },
    {
      title: 'Safe & Secure', 
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing acinam rutrum sit amet.'
    }
  ];
  const headerContent = (
    <div>
      <h6 className='textPara display-6 fs-4'>Get to Know About</h6>
      <h4 className='textHadd display-5 fw-bold'>Flexible and Quick <span className='textGreen'>Business<br/>Loans</span> For You in 3 easy steps!</h4>
      <p className=''>Get the capital you need to grow your business with our flexible and quick loan solutions. In just three simple steps.</p>
      <p>you can access the funds necessary to seize new opportunities and expand your operations.</p>
      <p>Don't let financing hold you back – discover the ease and convenience of our tailored lending options today!</p>
      <p>Nullam tincidunt ligula purus, eget aliquet urna maximus id. lorem nisi vel sapien sollicitudin rutrum.Quisque.</p>
       
    </div>
  );
  






  return ( 
    <>
    <NavBar/>
    <AboutHero head="About UpMyLoan"/>
    <AboutImg/>
    <AboutStep items={customItems} headerContent={headerContent} />
     <ImportantBar/>
    <FaqSection/>
    <RepExample/>
    <FooterSection/>
    </>
  )
}

export default AboutPage
