
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero' 
import LoanNotice  from '../components/LoanNotice'
import FooterSection from '../components/FooterSection'

const PersonalloanNotice = () => {  
 
  return ( 
    <>
    <NavBar/>
    {/* <AboutHero head=" "/>  */}
     <LoanNotice />
    <FooterSection/>
    </>
  )
}

export default PersonalloanNotice
