import React from 'react'
import Button from 'react-bootstrap/Button'; 
const HeroSlogan = ({ data }) => {
const { title, description, descriptionTwo, buttonText, note } = data;



return (
<div className="hero-content text-center">
       <h1 className='display-2 fw-bold text-white'>{title}</h1> 
        <p className='fw-lighter display-2 fs-1 textGreen'>{description}</p> 
        <p className='fw-semibold display-3 fs-3 text-white'>{descriptionTwo}</p> 
        <div className="d-flex justify-content-center">
        <div className="d-grid gap-2 col-lg-4 col-md-5 col-12">
          <a href="/form" className='text-decoration-none'>
      <Button size="lg" className='btnFill display-5 px-5'>
      {buttonText}
      </Button> 
      </a>
    </div>
    </div>


        <p className='fs-6 fw-normal text-white display-6 mt-3'>{note}</p>
      </div>
  )
}

export default HeroSlogan
