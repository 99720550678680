
import React from 'react' 
import NavBar from "../components/Navbar"
import AboutHero from '../components/AboutHero' 
import ImportantBar from '../components/ImportantBar'
import FaqSection from '../components/FaqSection'
import RepExample from '../components/RepExample'
import FooterSection from '../components/FooterSection'
import WhyChooseContent from '../components/WhyChooseContent'

const WhyChooseus = () => { 
 
  return ( 
    <>
    <NavBar/>
    <AboutHero head="Why Choose Us"/>
    <WhyChooseContent/> 
     <ImportantBar/>
    <FaqSection/>
    <RepExample/>
    <FooterSection/>
    </>
  )
}

export default WhyChooseus
