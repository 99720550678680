import React, { useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css"; 
import NavbarDark from "../components/NavbarDark";

function MultiStepForm() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.upmyloan.com/form-loader.js';
    script.async = true;

    document.body.appendChild(script);

    window.lmpost = window.lmpost || {};
    window.lmpost.options = {
      processingFlowId: 1,
      campaignUid: 'db4b6372-2beb-4204-b2e9-a89cec28aceb', // Added campaignUid
      theme: 'theme5',
      primaryColor: '#071741',
      jsfLightenPercent: 25,
      jsfDarkenPercent: -5,
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <NavbarDark />
      <div className='customSpace'>
        <jsf-form></jsf-form>
      </div>
      <p className="text-center my-3">
        <svg 
          aria-hidden="true" 
          width={16} 
          focusable="false" 
          data-prefix="fas" 
          data-icon="lock" 
          className="svg-inline--fa fa-lock" 
          role="img" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 448 512"
          alt
        >
          <path 
            fill="currentColor" 
            d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
          ></path>
        </svg>
        We use 256 bit SSL technology to encrypt your data. 
      </p>
    </>
  );
}

export default MultiStepForm;
