// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import Offcanvas from 'react-bootstrap/Offcanvas';    
import NavLogo from "../assets/img/upmyloanblk.png" 

function NavBar() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-white">
          <Container className='d-flex' fluid> 
            <Navbar.Brand href="/"><img src={NavLogo} width={100} alt='upmyloan'/></Navbar.Brand>
            <div className='d-flex justify-content-end'>
            <p className='textHadd fw-semibold fs-5 my-2 d-none d-sm-block'>Request a loan in under 2 minutes!</p> 
            <a href='/form' className="mx-3 px-4 py-2 btnBrdr text-decoration-none d-none d-sm-block">Get Started</a> 
            <Navbar.Toggle className='me-0' aria-controls={`offcanvasNavbar-expand-${expand}`} />  
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={NavLogo} width={100} alt='upmyloan'/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/aboutus">About Us</Nav.Link>
                  <Nav.Link href="/contactus">Contact Us</Nav.Link>
                  <Nav.Link href="https://blog.upmyloan.com">Blogs</Nav.Link>  
                  <Nav.Link href="/whychooseus">Why Choose Us</Nav.Link>
                  <Nav.Link href="/termsofservice">Terms of Service </Nav.Link>
                  <Nav.Link href="/privacypolicy">Privacy Policy </Nav.Link>
                  <Nav.Link href="/disclaimer">Disclaimer</Nav.Link>
                  <Nav.Link href="/econsent">eConsent </Nav.Link>
                  <Nav.Link href="/definition">Definition </Nav.Link>
                   <Nav.Link href="/ratesandfees">Rates and Fees</Nav.Link> 
                   <Nav.Link href="/fulldisclosureofterms">Full Disclosure of Terms</Nav.Link> 
                   <Nav.Link href="/latepaymentpolicy">Late Payment Policy</Nav.Link> 
                   <Nav.Link href="/nonpaymentpolicy">Non-Payment Policy</Nav.Link> 
                   <Nav.Link href="/loanrenewalpolicy">Loan Renewal Policy</Nav.Link>  
                  <Nav.Link href="/lendingpolicy">Lending Policy </Nav.Link>
 
                </Nav>
  
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar;