import React from 'react'

const AboutHero = (props) => {
  return (
    <div className='npHero'>
    <div className='container'>
      <h4 className='text-white text-center display-2 fs-2 fw-bold'>{props.head}</h4>
    </div>
    </div>
  )
}

export default AboutHero 
