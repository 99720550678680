
import React from 'react' 
import NavBar from "../components/Navbar"
// import AboutHero from '../components/AboutHero'  
import FooterSection from '../components/FooterSection'

const AdvertiserDisclosure = () => {  
 
  return ( 
    <>
    <NavBar/>
    <div className='costMarg'>
    <div className='container'>
    <div className='policy'>
    <div className="row">
    <div className="col-lg-9 col-md-8 col-12">
    <div>
      <h4>Non-Payment Policy</h4>
      <p> 
      Non-payment may lead to further costs as well as further action by the lender. Review any non-payment policy outlined in the loan agreement before you commit and sign. At UpMyLoan.com we make efforts to only work with reputable lenders and lending partners who will use fair and reasonable strategies for recovering outstanding debt.
</p>
   </div>
</div>
<div className="col-lg-3 col-md-4 col-12">
<div className="sticky-top">
<div className="getStarted">
<h4 className='display-1 fs-3 mb-4'>Need Fast Loan ?</h4>
<a href='/form' className='d-block btnFill display-5 px-3 text-center text-decoration-none'>
Get Started
</a>
</div>
</div>
</div>
</div>

    </div>
    </div>
    </div>
    <FooterSection/>
    </>
  )
}

export default AdvertiserDisclosure
