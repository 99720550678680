import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Mail from "../assets/img/mailclr.svg";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    loanType: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    } 
    if (!formData.loanType.trim()) {
      newErrors.loanType = 'Loan type is required';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Send form data to JSONPlaceholder API
        const response = await axios.post('https://jsonplaceholder.typicode.com/posts', formData);
        console.log('Form submitted:', response.data);
        // Display success message
        setSuccessMessage('Form submitted successfully!');
        // Reset form data
        setFormData({
          name: '',
          phone: '',
          email: '',
          loanType: '',
          message: ''
        });
        // Clear errors
        setErrors({});
      } catch (error) {
        console.error('Error submitting form:', error);
        // You can handle errors here, such as displaying an error message to the user
      }
    }
  };


    const items = [
         { 
          desc: 'care @ upmyloan.com', 
          icon: Mail,
         }  
      ];
  return (
    <div className='costMarg'>
    <div className='container'>
    <div className='row'>
      <div className='col-lg-4 col-md-4 col-12'>
        <div className='contactDetails'>
            <h6 className='display-5 fs-4 fw-semibold textGreen'>Get in touch</h6>
            <h4 className='display-1 fs-1 fw-bold textHadd'>Ask for your query</h4>
            <p className='display-4 fs-4 fw-normal lh-base textPara'>Various versions have evolved over the years sometimes by accident sometimes on purpose injected humour & the like.</p>
            <div className='row'>
      {items.map((item, index) => ( 
    <div key={index} className='col-md-12 col-12'> 
    <div className="d-flex mt-3" >
  <img  src={item.icon} alt={item.title} width={28} height={28} />
  <div className=" ms-2"> 
    <p className="display-5 fs-5 fw-normal textPara p-0 m-0"> 
        {item.desc}
        </p>
  </div>
</div> 
    </div>
     ))}
     </div>

        </div>
      </div>
      <div className='col-lg-8 col-md-8 col-12'>
      <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridName"> 
          <Form.Control type="text" placeholder="Full Name" size="lg" className='formColor'
           name="name"
           value={formData.name}
           onChange={handleChange}
           isInvalid={!!errors.name} />
           <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPhone"> 
          <Form.Control type="tel" placeholder="+123 4567 8900" size="lg"  className='formColor'   name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone} />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail"> 
          <Form.Control type="email" placeholder="Enter email" size="lg"  className='formColor'       name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
 
          <Form.Group as={Col} controlId="formGridState"> 
          <Form.Select defaultValue="Choose..." className='formColor'  name="loanType"
                    value={formData.loanType}
                    onChange={handleChange}
                    isInvalid={!!errors.loanType}>
          <option value="">Choose...</option>
          <option>Home Loan</option>
            <option>Business Loan</option>
            <option>Car Loan</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.loanType}</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1"> 
        <Form.Control as="textarea" rows={4} className='formColor' placeholder='Message'  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!errors.message} />
                  <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
      </Form.Group>
 
      <div className="d-grid gap-2">
      <Button  variant="primary" size="lg" type="submit" className="btnFill text-center">
        Submit
      </Button> 
    </div>
    
{successMessage && <div className="alert alert-success">{successMessage}</div>}
    </Form> 
      </div>
    </div>
    <div className='my-3 text-center'>
        <h4 className='display-4 fs-4 fw-bold'>We recognize that privacy is important to you! Our Privacy Policy outlines the types of personal information we collect and receive when you use our services, your rights to your personal information, and also the steps we take to safeguard your information.</h4>
        <p className='display-6 fs-6 fw-normal lh-base'>        
We hope this will help you make an informed decision about sharing personal information with us. If you change your mind about receiving communications from us and would like to unsubscribe from further communications, or would like us to delete your personal information, you may notify us of your preferences below. We have no control over the information that Ad Networks or other third parties may have about you. We encourage you to visit their websites and review and read the privacy policies and opt-out practices that govern their usage.
   </p>
 </div>
    </div>
    </div>
  )
}

export default ContactSection;

